import React, { useRef } from 'react'
import Container from '../../Components/Container'
import { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';


const Ticks = () => {

    const [data, setData] = useState([])
    const [modal, setModal] = useState(false)
    const [balance, setBalance] = useState(0)
    const [total, setTotal] = useState(0)
    const [id, setId] = useState('')
    const [name, setName] = useState('')
    const barcodeRef = useRef()

    useEffect(() => {

        const formdata = new FormData()
        formdata.append('action', 'select')
        fetch(process.env.REACT_APP_BASE_URL + 'tick.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {
                console.log(data)
                setData(data[0])
            })



    }, [])

    const takeHandel = (item) => {
        setModal(true)
        setBalance(item.balance)
        setId(item.sno)


    }


    const fetchHandel = () => {
        const formdata = new FormData()
        formdata.append('action', 'update')
        formdata.append('sno', id)
        formdata.append('balance', balance - total)
        formdata.append('date', new Date().toLocaleDateString())
        fetch(process.env.REACT_APP_BASE_URL + 'tick.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {
                if (data.status != 400) {
                    toast.success(data.message)
                    setTimeout(() => {
                        window.location.reload()
                    }, 500);
                } else {
                    toast.error(data.message)
                }
            })
    }

    const modalHandel = (e) => {
        e.preventDefault()
        if (e.target.classList.contains('close')) {
            setModal(false)
        }
    }


    const filterHandel = () => {
        const formdata = new FormData()
        formdata.append('action', 'find')
        formdata.append('name', name)
        fetch(process.env.REACT_APP_BASE_URL + 'tick.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {
                setData([data])
            })
    }

    const handlePrint = (item) => {


        const printContents = `
        <table>
        <thead>
        <tr>
        <td>
        <a style='font-size:8px; font-weight:light;'>Ad Soyad</a>
        </td>
        <td>
        <a style='font-size:8px; font-weight:light;'>Kalan Tutar</a>
        </td>
        <td>
        <a style='font-size:8px; font-weight:light;'>Son Ödeme Tarihi</a>
        </td>
        </tr>
        </thead>
        <tbody>
         <tr>
        <td>
        <a style='font-size:8px; font-weight:light;'>${item.name + ' ' + item.surname}</a>
        </td>
        <td>
        <a style='font-size:8px; font-weight:light;'>${item.balance} TL</a>
        </td>
        <td>
        <a style='font-size:8px; font-weight:light;'>${item.lastDate}</a>
        </td>
        </tr>
        </tbody>
        </table>
        
        
        
        `




        // Yeni bir pencere açıyoruz ve sadece barcode içeriğini yazdırıyoruz
        const printWindow = window.open('')
        printWindow.document.write('<html><head><title>Barcode</title>');
        // Yazdırma için özel stil ekliyoruz
        printWindow.document.write(`<style>
            @page {
                size: 58mm 40mm;
                margin: 0;
            }


            table{
                border-collapse:collapse;
                border:1px solid black;
                
            }
            table tr td {
                border:1px solid black ;
                padding:2px;
            }
            body {
                margin: 0;
                padding: 0;
                width: 58mm;
                height: 40mm;
                font-family: Arial, sans-serif;
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
            }
            .label {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
            }
            .barcode {
                width: 100%;
                height: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .details {
                width: 100%;
                height: 25%;
                display: flex;
                justify-content: space-between;
                padding: 0 5mm;
                box-sizing: border-box;
                font-size: 12px;
            }
            .name {
                text-align: center;
                fonst-weight:light;
            }
            .name {
                text-align: left;
            }
            .price {
                font-weight:bold;
                text-align: right;
            }
        </style>`);
        printWindow.document.write("</head><body>");
        printWindow.document.write(printContents);
        printWindow.document.write('</body></html>');
        printWindow.document.close();
        printWindow.print();
    };

    return (
        <Container>
            <Toaster position='top-center'></Toaster>
            {
                modal && <div onClick={(e) => { modalHandel(e) }} className='w-full p-4 lg:p-12  close h-screen bg-black/50 z-50 fixed top-0 left-0 flex items-center justify-center'>
                    <div className='bg-white rounded-md lg p-4 w-full  max-h-screen  gap-12 flex items-start justify-start flex-col'>
                        <span className='w-full flex gap-4 items-center justify-center'>
                            <label>Tutar</label>
                            <input type='number' onChange={(e) => setTotal(e.target.value)} value={total} className='w-full flex flex-1 h-10' placeholder=''></input>
                        </span>
                        <span className='w-full flex gap-4 items-center justify-end'>
                            <label>
                                Kalan bakiye: {balance - total}
                            </label>
                        </span>
                        <button onClick={() => { fetchHandel() }} className='w-full flex items-center justify-center text-center text-white rounded-md bg-green-500 h-16'>Tamamla</button>
                    </div>
                </div>
            }
            <div className='w-full flex items-center gap-4 justify-center shadow-sm shadow-black/50 rounded-md p-2'>
                <input onChange={(e) => { setName(e.target.value) }} value={name} className='w-full flex flex-1 h-10' placeholder='İsim'></input>
                <button onClick={() => { filterHandel() }} className='w-32 bg-green-500  p-2 rounded-md text-white'>Ara</button>
            </div>
            <div className='w-full '>
                <table className="table-auto w-full text-left">
                    <thead className=" bg-gray-500 text-white">
                        <tr>
                            <td className="py-3 border text-center font-bold p-4" contenteditable="true">Sno</td>
                            <td className="py-3 border text-center font-bold p-4" contenteditable="true">Ad</td>
                            <td className="py-3 border text-center font-bold p-4" contenteditable="true">Soyad</td>
                            <td className="py-3 border text-center font-bold p-4" contenteditable="true">Telefon</td>
                            <td className="py-3 border text-center font-bold p-4" contenteditable="true">Tarih</td>
                            <td className="py-3 border text-center font-bold p-4 px-1" contenteditable="true">Son Ödeme</td>
                            <td className="py-3 border text-center font-bold p-4" contenteditable="true">Toplam</td>
                            <td className="py-3 border text-center font-bold p-4" contenteditable="true">İndirim</td>
                            <td className="py-3 border text-center font-bold p-4" contenteditable="true">Bakiye</td>
                            <td className="py-3 w-12 border text-center bg-white border-t-white border-r-white  border-b font-bold p-4" contenteditable="true"></td>
                        </tr>
                    </thead>
                    <tbody className="bg-white text-gray-500">
                        {
                            data && data.map((item, index) => {
                                return (
                                    <tr className="py-1" ref={barcodeRef}>
                                        <td className="py-1 border text-center" >{item.sno}</td>
                                        <td className="py-1 border text-center" >{item.name}</td>
                                        <td className="py-1 border text-center" >{item.surname}</td>
                                        <td className="py-1 border text-center" >{item.phone}</td>
                                        <td className="py-1 border text-center" >{item.date}</td>
                                        <td className="py-1 border text-center" >{item.lastDate}</td>
                                        <td className="py-1 border text-center" >{item.total}</td>
                                        <td className="py-1 border text-center" >{item.discount}</td>
                                        <td className="py-1 border text-center" >{item.balance}</td>
                                        <td className="py-1 border text-center flex flex-col items-center justify-center gap-2" >
                                            <button onClick={(e) => { takeHandel(item) }} className='w-full bg-green-500 rounded-md p-4 text-white text-nowrap'>Ödeme Al</button>
                                            <button onClick={(e) => { handlePrint(item) }} className='w-full bg-indigo-500 rounded-md p-4 text-white text-nowrap'>Fiş Çıkar</button>
                                        </td>

                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>

        </Container>
    )
}

export default Ticks