import React, { useEffect } from 'react'
import Container from '../../Components/Container'
import { useState } from 'react'
import 'react-date-range/dist/styles.css'; // main style file
import DataTable from 'react-data-table-component';
import toast, { Toaster } from 'react-hot-toast';
import { da } from 'date-fns/locale';



const Notification = () => {



    const [open, setOpen] = useState(false)
    const [openA, setOpenA] = useState(false)
    const [product, setProduct] = useState('')
    const [id, setİd] = useState()
    const [count, setCount] = useState(0)
    const [update, setUpdate] = useState(false)
    const [data, setData] = useState([])
    const [products, setProducts] = useState([])
    const [barcode, setBarcode] = useState('')


    useEffect(() => {
        const formdata = new FormData()
        formdata.append('action', 'select')
        fetch(process.env.REACT_APP_BASE_URL + 'notification.php', {
            method: 'POST',
            body: formdata
        })
            .then(res => res.json())
            .then(data => {
                setData(data)
            })
        const formdata2 = new FormData()
        formdata2.append('action', 'select')
        fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
            method: 'POST',
            body: formdata
        })
            .then(res => res.json())
            .then(data => {
                setProducts(data)
            })



    }, [])


    const submitHandel = () => {
        const formdata = new FormData()
        formdata.append('action', 'insert')
        formdata.append('product', product.replace('-', ''))
        formdata.append('count', count)
        formdata.append('barcode', barcode)
        fetch(process.env.REACT_APP_BASE_URL + 'notification.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {
                if (data.status != 200) {
                    toast.error('Bir hata oldu')
                } else {

                    toast.success('Bildirim başarıyla kaydedildi')
                    setOpen(!open)

                    setInterval(() => {
                        window.location.reload()
                    }, 300);
                }
            })

    }


    const toggleHandeler = (e) => {
        e.preventDefault()
        if (e.target.classList.contains('close')) {
            setOpen(false)
        }
    }
    const toggleHandelerA = (e) => {
        e.preventDefault()
        if (e.target.classList.contains('close')) {
            setOpen(false)
        }
    }



    const columns = [
        {
            name: 'No',
            selector: row => row.id,
            sortable: true,
        },
        {
            name: 'Ürün',
            selector: row => row.product.replace('_', ' '),
            sortable: true,
        },
        {
            name: 'Adet',
            selector: row => row.count,
            sortable: true,
        },
        {
            name: 'İşlem',
            selector: row => <div className='flex items-center gap-4'>
                <button onClick={() => { updateHandel(row) }} className='bg-indigo-400 text-white px-4 py-2 rounded-md'>Düzenle</button>
                <button onClick={(e) => { deleteHandel(row.id) }} className='bg-red-400 text-white px-4 py-2 rounded-md'>Sil</button>
            </div>
        }
    ];

    const updateHandel = (row) => {

        setOpen(true)
        setUpdate(true)
        setProduct(row.product)
        setCount(row.count)
        setİd(row.id)





    }


    const allİtemsSet = () => {

        const formdata = new FormData()
        formdata.append('action', 'allİtemsSet')
        formdata.append('count', count)
        formdata.append('role', localStorage.getItem('role'))
        fetch(process.env.REACT_APP_BASE_URL + 'notification.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {
                toast('Bu işlem biraz sürebilir',
                    {
                        duration: 2000
                    }
                )



                if(data.status != 200){
                    toast.error(data.message)
                }else{
                    toast.success(data.message)
                }

            })


    }

    const fetchHandel = () => {

        const formdata = new FormData()
        formdata.append('action', 'update')
        formdata.append('id', id)
        formdata.append('product', product)
        formdata.append('barcode', barcode)
        formdata.append('count', count)
        fetch(process.env.REACT_APP_BASE_URL + 'notification.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json()).then(data => {
            if (data.status != 200) {
                toast.error('Bir hata oldu')
            } else {
                setOpen(false)
                toast.success('Silme işlmemi başarıyla yapıldı')
            }
        }).catch(err => {
            console.log(err)
        }
        )
    }

    const deleteHandel = (id) => {

        const formdata = new FormData()
        formdata.append('action', 'delete')
        formdata.append('id', id)
        fetch(process.env.REACT_APP_BASE_URL + 'notification.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {
                if (data.status != 200) {
                    toast.error('Bir hata oldu')
                } else {
                    toast.success('Silme işlmemi başarıyla yapıldı')
                }
            }
            )
            .catch(e => console.error(e))


    }



    const optionHandel = (e) => {
        setProduct(e.target.value.split('-')[0])
        setBarcode(e.target.value.split('-')[1])
    }

    return (
        <Container>
            <Toaster position='top-center'></Toaster>
            {
                open && <div onClick={(e) => { toggleHandeler(e) }} className='w-full p-4 lg:p-0 close h-screen bg-black/50 z-50 fixed top-0 left-0 flex items-center justify-center'>

                    <div className='bg-white rounded-md p-4 w-full lg:w-1/2 gap-6 flex items-start justify-start flex-col'>
                        <h1 className='text-2xl font-semibold text-gray-700'>Yeni Bildirim Ekle</h1>
                        <span className='w-full flex items-center justify-between gap-4 '>
                            <label htmlFor="product" className='text-gray-700'>Ürün</label>
                            <input list='a' onChange={e => optionHandel(e)} className='p-2 border w-full border-gray-300 rounded-md'></input>
                            <datalist id='a' defaultValue={product} name="product" className='p-2 border w-full border-gray-300 rounded-md'>
                                <option value={0} >Ürün Seçiniz</option>
                                {
                                    products.map((product) => {
                                        return (
                                            <option value={product.name + '-' + product.barcode} data-barcode={product.barcode} key={product.id}>{product.barcode}</option>
                                        )
                                    })
                                }
                            </datalist>
                        </span>
                        <span className='w-full flex items-center justify-between gap-4 '>
                            <label htmlFor='count' className='text-gray-700'>Adet</label>
                            <input onChange={(e) => { setCount(e.target.value) }} value={count} type='number' placeholder='Adet' className='p-2 border w-full border-gray-300 rounded-md' />
                        </span>
                        <div className='w-full flex items-center justify-end'>
                            {
                                !update && <button onClick={() => { submitHandel() }} className='bg-indigo-400 text-white px-4 py-2 rounded-md'>Kaydet</button>
                            }
                            {
                                update && <button onClick={() => { fetchHandel() }} className='bg-indigo-400 text-white px-4 py-2 rounded-md'>Güncelle</button>
                            }
                        </div>
                    </div>
                </div>
            }
            {
                openA && <div onClick={(e) => { toggleHandelerA(e) }} className='w-full p-4 lg:p-0 close h-screen bg-black/50 z-50 fixed top-0 left-0 flex items-center justify-center'>

                    <div className='bg-white rounded-md p-4 w-full lg:w-1/2 gap-6 flex items-start justify-start flex-col'>
                        <h1 className='text-2xl font-semibold text-gray-700'>Yeni Bildirim Ekle</h1>
                        <div className='w-full flex flex-col items-start gap-4 justify-center bg-yellow-400 p-2 rounded-md'>
                            <h1 className='text-lg text-white'>* Uyarı bu işlem bütün ürünler için yapılır</h1>
                            <h1 className='text-lg text-white'>* Eski uyarılar silinir</h1>

                        </div>
                        <span className='w-full flex items-center justify-between gap-4 '>
                            <label htmlFor='count' className='text-gray-700'>Adet</label>
                            <input onChange={(e) => { setCount(e.target.value) }} value={count} type='number' placeholder='Adet' className='p-2 border w-full border-gray-300 rounded-md' />
                        </span>
                        <div className='w-full flex items-center justify-end'>
                            <button onClick={() => { allİtemsSet() }} className='bg-indigo-400 text-white px-4 py-2 rounded-md'>Kaydet</button>
                        </div>
                    </div>
                </div>
            }
            <div className='w-full flex items-center justify-between relative'>
                <h1 className='text-2xl font-semibold text-gray-700'>Ürün Bildirimi</h1>
                <div className='w-full flex items-center justify-end gap-2'>
                    <button onClick={() => { setOpen(true) }} className='bg-indigo-400 text-white px-4 py-2 rounded-md'>Yeni Bildirim Ekle</button>
                    <button onClick={() => { setOpenA(true) }} className='bg-green-400 text-white px-4 py-2 rounded-md'>Bütün ürünleri denetle</button>
                </div>
            </div>
            <div className='w-full flex items-center justify-center'>
                <input type="text" onChange={(e) => { setProduct(e.target.value) }} value={product} placeholder='Ara...' className='w-full p-2 border border-gray-300 rounded-md' />
            </div>
            <DataTable
                columns={columns}
                data={data}
                pagination

            />


        </Container>
    )
}

export default Notification