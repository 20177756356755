import Container from '../../Components/Container'
import { FaCamera } from "react-icons/fa6";
import React, { useEffect, useRef, useState } from 'react';
import { BarcodeScanner, useTorch } from "react-barcode-scanner"
import "react-barcode-scanner/polyfill"
import SalesCard from '../../Components/SalesCard';
import toast, { Toaster } from 'react-hot-toast';
import {Link} from 'react-router-dom'



const Tick = () => {


  const ref = React.useRef(null)
  const barcodeText = useRef()
  const sellBtn = useRef(null)
  const [count, setCount] = useState(1)
  const [open, setOpen] = useState(false)
  const [modal, setModal] = useState(false)
  const [barcode, setBarcode] = useState('')
  const [discount, setDiscount] = useState(0)
  const [basket, setBasket] = useState(window.localStorage.getItem('tickBasket') ? JSON.parse(window.localStorage.getItem('tickBasket')) : [])
  const [total, setTotal] = useState(1)
  const [isSupportTorch, , onTorchSwitch] = useTorch()
  const [alert, setAlert] = useState()
  const [type, setType] = useState(false)
  const [per, setPer] = useState(0)
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [phone, setPhone] = useState('')

  const toggleHandeler = (e) => {
    e.preventDefault()
    if (e.target.classList.contains('close')) {
      setOpen(false)
    }
  }
  const modalHandel = (e) => {
    e.preventDefault()
    if (e.target.classList.contains('close')) {
      setModal(false)
    }
  }

  const sellHandel = () => {
    setModal(true)
  }

  const totalHandel = () => {

    if (type == true) {

      let total = 0
      basket.map((item) => {
        total += item.price * item.count
      })

      let d = (total * per) / 100

      return total - d

    } else {
      let total = 0
      basket.map((item) => {
        total += item.price * item.count
      })
      return total - discount
    }


  }

  const fetchHandel = () => {


    setLoading(true)


    const userInfo = new FormData()
    userInfo.append('action', 'add')
    userInfo.append('name', name)
    userInfo.append('surname', surname)
    userInfo.append('phone', phone)
    userInfo.append('date', new Date().toLocaleDateString())
    userInfo.append('lastDate', new Date().toLocaleDateString())
    userInfo.append('total',totalHandel())
    userInfo.append('discount', discount / basket.length)
    fetch(process.env.REACT_APP_BASE_URL + 'tick.php', {
      method: 'POST',
      body: userInfo
    }).then(res => res.json()).then(data => {
      if (data.status != 400) {
        const formdata = new FormData()
        formdata.append('action', 'sell')
        formdata.append('total', totalHandel())
        formdata.append('discount', discount / basket.length)
        formdata.append('user', window.localStorage.getItem('user') ? window.localStorage.getItem('user') : 'Anonim')
        formdata.append('date', new Date().toLocaleDateString())
        formdata.append('time', new Date().toLocaleTimeString())
        formdata.append('basket', JSON.stringify(basket))
        fetch(process.env.REACT_APP_BASE_URL + 'sales.php', {
          method: 'POST',
          body: formdata
        }).then(res => res.json()).then(d => {
          if (d.status != 400) {
            setLoading(false)
            toast.success('Satış Başarılı')
            setAlert(data.alert)
            setBasket([])
            setModal(false)
            setTotal(0)
            setDiscount(0)
            setBarcode('')

          } else {
            setLoading(false)
            toast.error('Satış Başarısız')
          }
        })
      }else{
        toast.error(data.message)
      }
    }
  
  )





  }
  const onCapture = (detected) => {
    if (detected) {
      const formdata = new FormData()
      formdata.append('action', 'findByBarcode')
      formdata.append('barcode', detected.rawValue)
      fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
        method: 'POST',
        body: formdata
      }).then(res => res.json()).then(data => {
        if (data.status != 400) {
          try {

            const itemCount = basket.filter((item) => item.barcode === detected.rawValue).length
            if (itemCount > 0) {
              const index = basket.findIndex((item) => item.barcode === detected.rawValue)
              if (count > 1) {
                basket[index].count = basket[index].count + parseInt(count)
                setTotal((prev) => prev + parseInt(basket[index].price) * parseInt(count))
              } else {
                basket[index].count += 1
                setTotal((prev) => prev + parseInt(basket[index].price))
              }
              setBasket([...basket])
              setOpen(false)
              setBarcode(detected.rawValue)

            } else {
              const p = parseInt(data.data.price)
              setBasket([...basket, {
                barcode: detected.rawValue,
                name: data.data.name,
                image: data.data.image,
                cost: data.data.cost,
                count: count,
                price: p,
                store: data.data.store,
                category: data.data.category
              }])
              setTotal(total + p)
            }

            setOpen(false)

            setBarcode(detected.rawValue)
            ref.current.focus()
          } catch (error) {
            console.log(error)
          }
        } else {
          const role = window.localStorage.getItem('role')
          if (role === 'admin') {
            window.location.href = '/dashboard/addProduct'
          } else {
            toast.error('Ürün Bulunamadı')

          }
        }
      })
    }


  }


  const keyHandel = (e) => {
    if (e.key === 'Enter') {
      const formdata = new FormData()
      formdata.append('action', 'findByBarcode')
      formdata.append('barcode', barcode)
      fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
        method: 'POST',
        body: formdata
      }).then(res => res.json()).then(data => {
        if (data.status != 404) {
          const itemCount = basket.filter((item) => item.barcode === barcode).length
          if (itemCount > 0) {
            const index = basket.findIndex((item) => item.barcode === barcode)
            if (count > 1) {
              basket[index].count = basket[index].count + parseInt(count)
              setTotal((prev) => prev + parseInt(basket[index].price) * parseInt(count))
            } else {
              basket[index].count += 1
              setTotal((prev) => prev + parseInt(basket[index].price))
            }
            setBasket([...basket])
            setOpen(false)
          } else {
            const p = parseInt(data.price)
            setBasket([...basket, {
              image: data.data.image,
              name: data.data.name,
              cost: data.data.cost,
              barcode,
              count: count,
              price: parseInt(data.data.price),
              store: data.data.store,
              category: data.data.category

            }])
            setTotal(total + p)
          }
          setOpen(false)
          setBarcode("")

        }
        else {
          const role = window.localStorage.getItem('role')
          if (role === 'admin') {
            const c = window.confirm('Ürün Bulunamadı, Eklemek ister misiniz?')
            if (c) {
              window.location.href = '/dashboard/addProduct'
            }

          } else {
            toast.error('Ürün Bulunamadı')

          }
        }

      }
      )
    }

  }

  const clearBasket = () => {
    setBasket([])
    setBarcode('')
  }

  useEffect(() => {

    if (modal) {

    }


  }, [discount])

  useEffect(() => {
    window.localStorage.setItem('tickBasket', JSON.stringify(basket))
    console.log(basket)
  }, [basket])



  const options = {

    formats: [
      'ean_13',
      'code_128',
      'code_39',
      'code_93',
      'codabar',
      'ean_8',
      'itf',
      'qr_code',
      'upc_a',
      'upc_e',
    ]
  }



  useEffect(() => {
    const formdata = new FormData()
    formdata.append('action', 'list')
    fetch(process.env.REACT_APP_BASE_URL + 'product.php', {
      method: 'POST',
      body: formdata
    }).then(res => res.json())
      .then(data => {
        setList(data)
      })
  }, [])


  return (
    <Container>
      {
        alert != null ?
          <div onClick={(e) => { e.target.remove() }} className='w-full p-4 lg:p-12  close h-screen bg-black/50 z-50 fixed top-0 left-0 flex items-center justify-center'>
            <div className='bg-white p-4 text-red-500 text-2xl capitalize rounded-md flex flex-col'>
              {
                alert
              }
            </div>
          </div> : null
      }
      <Toaster position='top-center'></Toaster>
      {
        modal && <div onClick={(e) => { modalHandel(e) }} className='w-full p-4 lg:p-12 flex-col gap-6  close h-screen bg-black/50 z-50 fixed top-0 left-0 flex items-center justify-center'>
          <div className='w-full h-auto py-4 flex flex-col items-center justify-center gap-4 bg-white p-2 rounded-md '>
            <span className='w-full flex items-center justify-center gap-4'>
              <input type='text' onChange={(e) => { setName(e.target.value) }} value={name} placeholder='İsim' className='w-full p-2 border border-gray-300 rounded-md shadow-sm shadow-black/50' />
              <input type='text' onChange={(e) => { setSurname(e.target.value) }} value={surname} placeholder='Soyisim' className='w-full p-2 border border-gray-300 rounded-md shadow-sm shadow-black/50' />
            </span>
            <input type='text' onChange={(e) => { setPhone(e.target.value) }} value={phone} placeholder='Telefon' className='w-full p-2 border border-gray-300 rounded-md shadow-sm shadow-black/50' />

          </div>
          <div className='bg-white rounded-md lg p-4 lg:h-full w-full overflow-y-scroll max-h-screen  gap-12 flex items-start justify-start flex-col'>
            {
              basket && basket.map((item) => {
                return (
                  <span key={item.barcode} className='w-full flex items-center  justify-between'>
                    <p>
                      {
                        item.name
                      }
                    </p>
                    <p>
                      {
                        item.barcode
                      }
                    </p>
                    <span className='flex flex-col items-center justify-center gap-1'>
                      <p>
                        {
                          parseInt(item.count)
                        }
                        adet
                      </p>



                    </span>
                  </span>
                )
              })
            }
            <span className={'w-full flex items-center justify-end text-black'}>
              Toplam : {totalHandel()} TL
            </span>

            <div className='w-full flex flex-col items-center justify-center gap-2'>
              <span className='w-full flex items-center justify-center gap-4'>
                {
                  !type && <input value={discount} min={0} onChange={(e) => { setDiscount(e.target.value) }} type='number' placeholder='İndirim' className='w-full p-2 border border-gray-300 rounded-md' />
                }
                {
                  type && <input min={0} max={100} value={per} onChange={(e) => { setPer(e.target.value) }} type='number' placeholder='İndirim' className='w-full p-2 border border-gray-300 rounded-md' />
                }
                <button onClick={() => { setType(!type) }} className={'bg-green-600 w-full tracking-wide text-white rounded-md px-4 py-2 '}>
                  {
                    type == true ? 'Yüzdesel' : 'Rakamsal'
                  }
                </button>
              </span>
              <button disabled={loading} onClick={() => { fetchHandel() }} ref={sellBtn} className={'bg-green-600 disabled:bg-gray-300 w-full tracking-wide text-white rounded-md p-4 '}>Tamamla</button>

            </div>
          </div>
        </div>
      }
      {
        open && <div onClick={(e) => { toggleHandeler(e) }} className='w-full p-4 lg:p-12  close h-screen bg-black/50 z-50 fixed top-0 left-0 flex items-center justify-center'>
          <div className='bg-white rounded-md lg p-4 lg:h-full w-full  gap-6 flex items-start justify-start flex-col'>
            <BarcodeScanner options={options} onCapture={onCapture} />
            <div className='w-full flex items-center gap-2 justify-between'>
              <input type='number' onChange={(e) => { setCount(e.target.value) }} value={count} placeholder='Adet' className='w-full p-2 border border-gray-300 rounded-md' />
              {isSupportTorch ? (
                <button className='bg-yellow-400 whitespace-nowrap text-black px-4 py-2 rounded-md' onClick={onTorchSwitch}>El Feneri</button>
              ) : null}
            </div>
          </div>
        </div>
      }
      <div className='w-full flex flex-col items-start min-h-screen h-auto justify-start relative gap-4'>
        <div className='w-full flex-col flex items-center gap-2 justify-between'>
          <span className='w-full flex items-center justify-center gap-4'>
            <input type='text' ref={barcodeText} onKeyDown={(e) => { keyHandel(e) }} value={barcode} onChange={(e) => { setBarcode(e.target.value) }} placeholder='Barkod giriniz' className='w-full p-2 border border-gray-300 rounded-md' />
            {
              basket.length > 0 && <button onClick={() => { clearBasket() }} className='bg-red-500 whitespace-nowrap text-white rounded-md p-2'>Sepeti Temizle</button>
            }
            <button onClick={() => { setOpen(true) }} className='bg-indigo-500 flex group items-center justify-center gap-1 rounded-md text-white whitespace-nowrap h-10 px-4'>
              <FaCamera />
              <p className='w-0 group-hover:w-32 overflow-hidden transition-all'>
                Kamera ile oku
              </p>
            </button>
            <Link to={'/dashboard/ticks'} className='bg-green-500 flex group items-center justify-center gap-1 rounded-md text-white whitespace-nowrap h-10 px-4'>Kulanıcıları Göster</Link>
          </span>
          <select onChange={(e) => { setBarcode(e.target.value) }} className='p-2 border w-full border-gray-300 rounded-md'>
            <option>
              Hızlı ürün seçiniz
            </option>
            {
              list && list.map((item) => {
                return (
                  <option value={item.barcode} key={item.id}>{item.name}</option>
                )
              })
            }
          </select>

        </div>

        <div className={basket.length > 0 ? 'absolute bg-slate-100 shadow-sm shadow-black/25 left-0 bottom-0  w-full flex items-center justify-between text-white rounded-md p-4 ' : 'hidden'}>
          <span className='w-full flex items-center justify-start text-black'>
            Toplam : {totalHandel()} TL
          </span>
          <button onClick={() => { sellHandel() }} className={basket.length > 0 ? 'bg-green-600 tracking-wide text-white rounded-md p-4 ' : 'hidden'}>Tamamla</button>
        </div>
        {
          basket.length > 0 &&
          <div ref={ref} className='w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 place-items-center h-auto gap-4'>
            {
              basket.map((item, index) => {
                return (
                  <SalesCard item={item} func={setBasket} value={basket} key={item.barcode}></SalesCard>
                )
              })
            }
          </div>
        }
      </div>


    </Container>
  )
}

export default Tick