import React, { useEffect, useState } from 'react'
import Container from '../../Components/Container'
import toast, { Toaster } from 'react-hot-toast'

const List = () => {

    const [data, setData] = useState([])

    useEffect(() => {
        const formdata = new FormData()
        formdata.append('action', 'selectList')
        fetch(process.env.REACT_APP_BASE_URL + 'sales.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {
                console.log(data)
                setData(data[0])
            })
    }, [])


    const delteHandel = (item) => {
        const formdata = new FormData()
        formdata.append('action', 'deleteList')
        formdata.append('sno', item)
        fetch(process.env.REACT_APP_BASE_URL + 'sales.php', {
            method: 'POST',
            body: formdata
        }).then(res => res.json())
            .then(data => {

                if (data.status != 200) {
                    toast.error(data.message)
                } else {
                    toast.success(data.message)
                    setTimeout(() => {
                        window.location.reload()
                    }, 500);
                }

            })
    }
    return (
        <Container>
            <Toaster position='top-center'></Toaster>
            <div className='w-full flex items-center justify-start'>
                <h1>Alınacaklar</h1>
            </div>
            <div className='w-full flex items-start justify-start p-2'>
                <table className="table-fixed w-full text-left">
                    <thead className="uppercase bg-[#6b7280] text-[#e5e7eb]" >
                        <tr>
                            <td className="py-1 border text-center  p-4" contenteditable="true">Sno</td>
                            <td className="py-1 border text-center  p-4" contenteditable="true">Ürün resim</td>
                            <td className="py-1 border text-center  p-4" contenteditable="true">Ürün adı</td>
                            <td className="py-1 border text-center  p-4" contenteditable="true">Barkode</td>
                            <td className="py-1 border text-center  p-4" contenteditable="true">Eklenme Tarihi</td>
                            <td className="py-1 border text-center  p-4" contenteditable="true"></td>
                        </tr>
                    </thead>
                    <tbody className="bg-white text-gray-500 " >
                        {
                            data && data.map((item) => {
                                return (
                                    <tr className="py-5">
                                        <td className="py-5 border text-center  p-4" contenteditable="true">{item.sno}</td>
                                        <td className="py-5 border text-center  p-4" contenteditable="true"><img className='w-24 aspect-square' src={item.img}></img></td>
                                        <td className="py-5 border text-center  p-4" contenteditable="true">{item.name}</td>
                                        <td className="py-5 border text-center  p-4" contenteditable="true">{item.barcode}</td>
                                        <td className="py-5 border text-center  p-4" contenteditable="true">{item.date}</td>
                                        <td className="py-5 border text-center  p-4" contenteditable="true">
                                            <button onClick={() => { delteHandel(item.sno) }} className='w-full flex items-center justify-center p-4 bg-red-500 rounded-md text-white'>Sil</button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>
        </Container>
    )
}

export default List